:root {
  --locationsHeight: 72px;
}
@media (max-width: 1024px) {
  :root {
    --locationsHeight: 90px;
  }
}
@media (max-width: 767px) {
  :root {
    --locationsHeight: 100px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/* open-sans-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/extras/fonts/open-sans-v40-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('/extras/fonts/open-sans-v40-latin-italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/extras/fonts/open-sans-v40-latin-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-700italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('/extras/fonts/open-sans-v40-latin-700italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
address {
  font-style: normal;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2014-08-26 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}
.unit > .body,
.unit > form > .body {
  width: 100%;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 10px;
  margin-bottom: 10px;
}
.lead {
  margin-bottom: 0 !important;
}
.line {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 0;
  font-size: 0;
  line-height: 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #3E3E3D;
  color: #3E3E3D;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #3E3E3D !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 210;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 211;
  color: #3E3E3D;
  font-family: 'Open Sans', helvetica, sans-serif;
  line-height: 1.45;
  font-size: 16px;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #404040;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset,
#disp .part.recaptcha-info {
  padding: 0.2em 0 0.5em;
}
#disp .part.recaptcha-info {
  width: 100%;
  margin: 0;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: bold;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding: 0 15px 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  float: right;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #404040;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e64246;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
.srch .text {
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #bfbfbf;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  padding-bottom: 0.8em;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #009DE0;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #009DE0;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
.grow {
  text-transform: none !important;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 0;
}
.table--headline th {
  font-weight: bold;
}
.table td {
  padding: 5px 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: bold;
}
@media (max-width: 767px) {
  .table {
    display: block;
  }
  .table .table-head {
    display: none;
  }
  .table tr {
    float: left;
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding: 5px 10px;
    border: 1px solid #ccc;
    margin-top: 9px;
  }
  .table-body .table tr:first-child {
    margin-top: 0;
  }
  .table .table-body tr:first-child {
    margin-top: 0;
  }
  .table td {
    float: left;
    display: block;
    width: 100%;
  }
  .table.table--headline td {
    position: relative;
    box-sizing: border-box;
    padding-left: 52%;
  }
  .table.table--headline td:before {
    content: attr(data-title);
    position: absolute;
    left: 0;
    top: 0;
    width: 48%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
html,
body {
  min-height: 100%;
  height: 100%;
  background: #fff;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 157, 224, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  width: 100%;
  background-color: transparent;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 100%;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
#home {
  display: block;
  position: relative;
  float: left;
  padding-right: 150px;
}
#home:after {
  content: "Dentalklinik Frei";
  display: block;
  position: absolute;
  top: 4px;
  left: 35px;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.25;
  font-weight: 400;
  text-transform: uppercase;
  color: #3E3E3D;
}
.logo {
  width: 100%;
  height: auto;
}
#head {
  float: right;
  width: 100%;
}
.ie10 body {
  display: flex;
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  color: #3E3E3D;
  font-family: 'Open Sans', helvetica, sans-serif;
  line-height: 1.45;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-end;
}
.section {
  float: left;
  width: 100%;
}
.section--main {
  flex: 1 0 auto;
}
.head-container {
  float: left;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
#desk {
  margin: 0;
  padding: 0;
  display: none;
}
#cb-elastic-container {
  float: left;
  width: 100%;
}
.navbar {
  position: fixed;
  z-index: 15;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
  border-top: 4px solid #009DE0;
  background-color: #fff;
  transition: box-shadow 250ms;
}
.navbar.cb-elastic-content-small {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}
.navbar .navbar-inner {
  position: relative;
  max-width: 100%;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.backlink {
  position: fixed;
  width: 24px;
  height: 24px;
  background-image: url(/images/close-grey-dark.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  transition: background 250ms;
}
.backlink:hover,
.backlink:focus {
  background-image: url(/images/close-blue.svg);
}
.mood {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 53px;
}
.mood .moodcontent {
  position: absolute;
  z-index: 11;
}
#expo {
  position: relative;
  float: left;
  width: 100%;
  height: 25vw;
}
.cb-layout2 #expo {
  height: 35.41666667vw;
}
#slides {
  overflow: hidden;
  position: relative;
  max-width: 100% !important;
  max-height: 10000px !important;
  z-index: 1;
}
#slides .slide {
  width: 100%;
}
#slides img {
  display: block;
  width: 100%;
  max-width: 100% !important;
  max-height: 10000px !important;
}
@media only screen and (max-width: 1024px) {
  #expo {
    height: 40.625vw;
  }
  #slides {
    height: 40.625vw !important;
  }
  #slides .slide {
    width: 162.5vw;
    height: 40.625vw !important;
    left: 50%;
  }
  #slides img {
    margin-left: -81.25vw;
  }
}
@media only screen and (max-width: 1024px) {
  .cb-layout2 #expo {
    height: 54.6875vw;
  }
  .cb-layout2 #slides {
    height: 54.6875vw !important;
  }
  .cb-layout2 #slides .slide {
    width: 154.375vw;
    height: 54.6875vw !important;
    left: 50%;
  }
  .cb-layout2 #slides img {
    margin-left: -77.1875vw;
  }
}
#expo div.link {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
#expo div.link .prev,
#expo div.link .next {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 60px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 39px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
#expo div.link .prev.cb-slide-previous,
#expo div.link .next.cb-slide-previous {
  left: 0;
  background-image: url(/images/mood-prev.svg);
}
#expo div.link .prev.cb-slide-next,
#expo div.link .next.cb-slide-next {
  right: 0;
  background-image: url(/images/mood-next.svg);
}
#expo div.link .cb-index {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-end;
}
#expo div.link .cb-index .cb-index-some {
  display: none;
}
#expo div.link .cb-index .cb-index-all {
  margin: 0 auto;
}
#expo div.link .cb-index .cb-index-all a {
  position: relative;
  display: block;
  width: 10px;
  height: 10px;
  margin: 5px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 1;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
#expo div.link .cb-index .cb-index-all .this a {
  background-color: #009DE0;
}
.ie10 #expo div.link .prev,
.ie10 #expo div.link .next {
  top: 50%;
  width: 20px;
  height: 39px;
  margin-top: -20px;
  background-size: 100%;
}
.ie10 #expo div.link .prev.cb-slide-previous,
.ie10 #expo div.link .next.cb-slide-previous {
  left: 20px;
}
.ie10 #expo div.link .prev.cb-slide-next,
.ie10 #expo div.link .next.cb-slide-next {
  right: 20px;
}
.logonav-wrap {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section--footer {
  background-color: #ECECEB;
  padding-bottom: var(--locationsHeight);
}
.section--footer .desk {
  max-width: 93.75%;
}
.footcontent {
  float: left;
  width: 100%;
  margin: 100px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 14px;
  line-height: 1.57142857;
}
@media (max-width: 1024px) {
  .footcontent {
    margin: 40px 0;
  }
}
@media (max-width: 767px) {
  .footcontent {
    flex-direction: column;
  }
}
.footunit {
  float: left;
  max-width: 530px;
}
@media (max-width: 767px) {
  .footunit {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .footunit--1 {
    margin: 40px 0;
  }
}
.partners {
  float: left;
  display: flex;
  align-items: center;
}
@media (max-width: 1024px) {
  .partners {
    flex-direction: column;
    align-items: flex-start;
  }
}
.partners__section {
  float: left;
  display: flex;
  align-items: center;
}
.partners__logo {
  float: left;
  width: 60px;
  margin-right: 16px;
}
.partners__logo:last-child {
  margin-right: 0;
}
.partners__logoImage {
  float: left;
  width: 100%;
}
.partner__text {
  float: left;
  margin-left: 40px;
}
@media (max-width: 1024px) {
  .partner__text {
    margin-left: 0;
    margin-top: 20px;
  }
}
#cmsbox {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#cmsbox .meta {
  float: left;
}
.locations {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 100;
  width: 100%;
  background-color: #ECECEB;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-family: 'Open Sans', helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.57142857;
}
@media (max-width: 767px) {
  .locations {
    font-size: 12px;
    line-height: 1.33333333;
    border: none;
  }
}
.locations__content {
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
}
@media (max-width: 767px) {
  .locations__content {
    flex-direction: column;
  }
}
.location {
  float: left;
  display: flex;
  align-items: center;
  height: var(--locationsHeight);
  box-sizing: border-box;
  max-width: 50%;
  padding-left: 20px;
  padding-right: 200px;
  position: relative;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .location {
    flex-direction: column;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .location {
    padding-right: 140px;
    height: calc(var(--locationsHeight) / 2);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    max-width: 100%;
  }
}
.location__title {
  float: left;
  flex-shrink: 0;
  font-weight: bold;
  color: #009DE0;
  font-size: 20px;
  line-height: 1.4;
  margin-right: 20px;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .location__title {
    width: auto;
  }
}
@media (max-width: 767px) {
  .location__title {
    font-size: 13px;
    line-height: 1.53846154;
    width: 90px;
  }
}
.location__info {
  float: left;
  width: calc(100% - 160px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .location__info {
    width: auto;
    align-items: center;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .location__info {
    width: calc(100% - 90px);
  }
}
.location__button {
  position: absolute;
  right: 0;
  top: 0;
  width: 180px;
  height: 100%;
  background-color: #009DE0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.location__button:hover,
.location__button:focus {
  color: #fff;
  background-color: #008bc7;
}
@media (max-width: 767px) {
  .location__button {
    width: 140px;
  }
}
.location__link {
  white-space: nowrap;
}
h1 {
  color: #009DE0;
  font-weight: 300;
  line-height: 1.1;
  text-align: center;
}
.cb-layout2 h1 {
  color: #fff;
  text-align: left;
}
.cb-layout3 h1 {
  text-align: left;
}
h2 {
  font-weight: bold;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.33;
}
strong {
  font-weight: bold;
}
.text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
.open,
.load {
  overflow-wrap: normal;
  word-wrap: normal;
  hyphens: none;
}
a {
  color: #3E3E3D;
  text-decoration: none;
  transition: color 250ms;
}
a:hover,
a:focus {
  color: #009DE0;
}
.loud {
  font-weight: bold;
  color: #009DE0;
  padding-left: 20px;
  position: relative;
}
.loud:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 100%;
  background-color: #009DE0;
  mask-size: auto 20px;
  mask-position: 0 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/icon-pin.svg);
}
.area.farwest .unit {
  margin-top: 0;
  margin-bottom: 0;
}
.area.farwest .unit .part {
  margin-top: 0;
  margin-bottom: 0;
}
.unit.seam {
  margin-top: 0;
  margin-bottom: 0;
}
.unit .body {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}
.cb-layout2 .part.text {
  text-align: center;
}
.text-section {
  float: left;
  width: 100%;
}
.list--bullet {
  padding-left: 0;
  list-style-type: none;
}
.list--bullet li {
  padding-left: 24px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered li {
  margin-left: 16px;
}
div.load {
  background: none !important;
}
div.link .load,
div.load .load,
div.link .open,
div.load .open {
  text-decoration: underline;
  font-size: 20px;
  font-size: 2rem;
}
.cb-layout2 .pure .load {
  display: flex;
  justify-content: center;
}
.cb-layout2 .pure .load a.load {
  float: left;
  display: block;
  background-color: #006089;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border-radius: 0;
  padding: 8px 15px;
  -webkit-appearance: none;
  text-transform: uppercase;
  transition: all 0.8s cubic-bezier(0.05, 0.8, 0.5, 1);
  font-weight: bold;
  font-size: 20px;
  font-size: 2rem;
}
.cb-layout2 .pure .load a.load:hover,
.cb-layout2 .pure .load a.load:focus {
  color: #fff;
  background-color: #009DE0;
  text-decoration: none;
  cursor: pointer;
}
.cb-layout2 .pure .load a.load:active {
  outline: none;
  background-color: #0079ad;
}
@media (max-width: 767px) {
  .cb-layout2 .pure .load a.load {
    font-size: 16px;
    font-size: 1.6rem;
  }
}
@media (max-width: 1024px) {
  .cb-layout2 .pure .load a.load {
    font-size: 18px;
    font-size: 1.8rem;
  }
}
.pure .link .open {
  float: left;
  display: block;
  background-color: #006089;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border-radius: 0;
  padding: 8px 15px;
  -webkit-appearance: none;
  text-transform: uppercase;
  transition: all 0.8s cubic-bezier(0.05, 0.8, 0.5, 1);
  font-weight: bold;
  font-size: 20px;
  font-size: 2rem;
}
.pure .link .open:hover,
.pure .link .open:focus {
  color: #fff;
  background-color: #009DE0;
  text-decoration: none;
  cursor: pointer;
}
.pure .link .open:active {
  outline: none;
  background-color: #0079ad;
}
@media (max-width: 767px) {
  .pure .link .open {
    font-size: 16px;
    font-size: 1.6rem;
  }
}
@media (max-width: 1024px) {
  .pure .link .open {
    font-size: 18px;
    font-size: 1.8rem;
  }
}
.pure.wide .link {
  display: flex;
  justify-content: center;
}
.text .open {
  text-decoration: underline;
}
.pict img {
  margin: 0 auto;
}
.code-image {
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}
.unit.slim .part.pict.tiny img {
  border-radius: 50%;
}
/******* forms.less 2014-10-16 *******/
/* Changelog

2014-10-16
Checkboxes & Radiobuttons:
no float for inputs, no display block for labels

*/
fieldset {
  line-height: 1.45em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  margin-top: 0px;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #404040;
  background-color: #fff;
  font-weight: normal;
  font-family: 'Open Sans', helvetica, sans-serif;
  font-size: 16px;
  font-size: 1.6pxrem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.1em 3px;
  min-height: 1.45em;
  border: 1px solid #3E3E3D;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 16px;
  font-size: 1.6pxrem;
  color: #444;
  font-weight: normal;
  font-family: 'Open Sans', helvetica, sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #8c8c8c;
}
input.text[disabled],
textarea[disabled] {
  border-color: #4b4b4a;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #009DE0;
  box-shadow: 0 0 3px rgba(0, 157, 224, 0.7);
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #1ebcff;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #1ebcff;
}
input.submit,
button.cb-hybrid {
  float: left;
  display: block;
  background-color: #006089;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border-radius: 0;
  padding: 8px 15px;
  -webkit-appearance: none;
  text-transform: uppercase;
  transition: all 0.8s cubic-bezier(0.05, 0.8, 0.5, 1);
  font-weight: bold;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  color: #fff;
  background-color: #009DE0;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  background-color: #0079ad;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
  width: 100.3%;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: -3px 0.3em 0 0;
  min-height: 1.45em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
}
.form .ctrl .sign {
  min-width: 4em;
  display: inline-block;
  margin-left: 0.2em;
}
div.cb-form-sent {
  float: left;
  width: 100%;
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e64246;
  color: #e64246 !important;
}
div.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
.calendar {
  position: absolute;
  z-index: 100;
  border: 1px solid #666;
  background: #333;
  text-align: right;
  line-height: 2;
  color: #fff !important;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
.calendar > table {
  margin: 3px;
  border-spacing: 0;
  border-collapse: collapse;
}
.calendar th {
  color: #eee;
  text-align: center;
  font-weight: 700;
}
.calendar tbody {
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #666;
}
td.published {
  color: #7be;
  font-weight: 700;
}
td.show {
  background: #7be;
  color: #fff;
  font-weight: 700;
}
.calendar th > a,
.calendar td > a {
  display: block;
  padding: 0 6px 1px 6px;
  color: inherit;
  text-decoration: none;
}
.calendar th > a:hover,
.calendar td > a:hover {
  background: #666;
  color: #fff;
}
.calendar td.show {
  background: #87004e;
}
.calendar td.show a:hover {
  background: none;
}
/*** mobile-navi-multilevel-right-left.less 2014-11-03 ***/
.navi {
  display: none;
}
#mobile-navi {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  border-top: 5px solid #009DE0;
  z-index: 210;
  overflow-y: auto;
  background: #fff;
  box-shadow: 0 6px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in;
}
@media only screen and (min-width: 768px) {
  #mobile-navi {
    width: 320px;
  }
}
.togglenavigation {
  float: right;
  position: relative;
  display: block;
  width: 30px;
  height: 20px;
  padding: 4px 0;
  z-index: 211;
  cursor: pointer;
  color: #3E3E3D !important;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
}
.togglenavigation:active > span {
  background: #292929 !important;
}
.togglenavigation > span {
  left: 0;
  top: 50%;
  position: absolute;
  display: block;
  height: 4px;
  width: 30px;
  margin-top: -2px;
  background: currentColor;
}
.togglenavigation > .tline-1 {
  margin-top: -10px;
}
.togglenavigation > .tline-4 {
  margin-top: 6px;
}
.cb-toggle-target-active .togglenavigation > .tline-1,
.cb-toggle-target-active .togglenavigation > .tline-4 {
  opacity: 0;
}
.cb-toggle-target-active .togglenavigation > .tline-2,
.cb-toggle-target-active .togglenavigation > .tline-3 {
  transition: transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
}
.cb-toggle-target-active .togglenavigation > .tline-2 {
  transform: rotate(-45deg);
}
.cb-toggle-target-active .togglenavigation > .tline-3 {
  opacity: 1;
  transform: rotate(45deg);
}
body.cb-toggle-target-active #mobile-navi {
  transition: all 0.218s ease-out;
}
body.cb-toggle-target-active .togglenavigation {
  transition: all 0.4s cubic-bezier(0.21, 0.53, 0.44, 1.24);
}
/* RIGHT */
#mobile-navi {
  right: -100%;
}
body.cb-toggle-target-active #mobile-navi {
  right: 0;
}
/* LEFT */
#mobile-navi .navi {
  float: left;
  display: block;
  width: 100%;
}
#mobile-navi .navi .item {
  width: 100%;
  float: left;
  position: relative;
}
#mobile-navi .navi .item .menu {
  display: block;
  color: #3E3E3D;
  padding: 5px 15px;
  padding-right: 32px;
  font-size: 19px;
}
#mobile-navi .navi .item .menu:hover,
#mobile-navi .navi .item .menu:focus {
  color: #009DE0;
}
#mobile-navi .navi .item .menu.path {
  color: #009DE0;
}
#mobile-navi .navi.sub1 {
  margin-top: 48px;
}
#mobile-navi .navi.sub1 > .item {
  border-bottom: 1px solid #ECECEB;
}
#mobile-navi .navi.sub1 > .item.init {
  border-top: 1px solid #ECECEB;
}
#mobile-navi .navi.sub2 {
  max-height: 0;
  overflow: hidden;
}
#mobile-navi .navi.sub2 .item .menu {
  padding-left: 30px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.25;
}
#mobile-navi div.navi > .item.cb-toggle-target-active > div.navi {
  max-height: 1000px;
  transition: all 2s;
}
#mobile-navi .cb-toggle {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  width: 32px;
  height: 32px;
  background: url(/images/mnavitoggle.svg) no-repeat center;
  transition: all 0.218s;
}
#mobile-navi .cb-toggle:hover,
#mobile-navi .cb-toggle:focus {
  cursor: pointer;
}
#mobile-navi .cb-toggle.cb-toggle-active {
  transform: rotate(180deg);
}
#mobile-navi div.sub2 .cb-toggle {
  height: 34px;
}
#mobile-navi .navi > .cb-toggle,
#mobile-navi .navi .item-empty > .cb-toggle {
  display: none;
}
.desk {
  max-width: 90%;
  width: 640px;
}
#home {
  width: 28px;
  height: 28px;
}
#head {
  margin-top: 30px;
}
.cb-layout2 #head {
  max-width: 335px;
  margin-top: 48px;
}
.cb-layout3 #head {
  float: right;
  width: calc(100% - 38px - 20px);
  margin-top: 0;
}
.head-container {
  margin-top: 55px;
}
@media only screen and (max-width: 767px) {
  .section--main {
    min-height: calc(100vh - 100vw / 320 * 175 - 53px);
  }
}
.navbar .navbar-inner {
  max-width: 93.75%;
}
.backlink {
  right: 5vw;
  top: 63px;
}
.service_phone {
  margin-top: 4px;
}
.service_phone:before {
  top: 0px;
  width: 20px;
  height: 23px;
  background-size: 20px 23px;
}
@media (max-width: 767px) {
  .service_termin {
    margin-right: 0;
    margin-top: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 15px;
    width: 100%;
    box-sizing: border-box;
  }
}
.mood {
  position: relative;
  margin-top: 57px;
}
.mood .moodcontent {
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  width: 43.75%;
}
@media only screen and (max-width: 767px) {
  #expo div.link .prev,
  #expo div.link .next {
    display: none;
  }
  #expo div.link .cb-index {
    margin-bottom: 0px;
  }
}
h1 {
  font-size: 32px;
  font-size: 3.2rem;
}
.cb-layout2 h1 {
  font-size: 20px;
  font-size: 2rem;
}
.cb-layout3 h1 {
  font-size: 22px;
  font-size: 2.2rem;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .foot {
  margin-right: 3.24675325%;
  margin-left: 3.24675325%;
}
.area .part {
  margin-right: 3.24675325%;
  margin-left: 3.24675325%;
  width: 93.50649351%;
}
.area > .slim .part {
  width: 93.50649351%;
}
.area.farwest {
  width: 100%;
}
.area.farwest > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area.farwest .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.area.farwest .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area.farwest > .slim .part {
  width: 100%;
}
.area {
  width: 106.94444444%;
  margin-left: -3.47222222%;
  margin-bottom: 20px;
}
.area:empty {
  margin-top: 0;
}
.area.farwest {
  width: 38px;
  margin: 0;
}
.area .unit.seam .body {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}
.area .unit.seam .part.tall {
  width: 72.72727273%;
}
.area .unit.seam .part.tiny {
  width: 14.28571429%;
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 90px !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
/*# sourceMappingURL=./screen-small.css.map */